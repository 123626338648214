.hsw-form h5 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #061938 !important;
    margin-bottom: 16px;
    margin-top: 4px !important;
}

.hsw-form h4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff !important;
}

.hsw-label {
    position: absolute;
    top: -8px;
    left: 8px;
    font-size: 10px !important;
    line-height: 10px !important;
    background-color: white;
    padding-top: 2px;
    padding-bottom: 0;
    padding-right: 4px;
    padding-left: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.hsw-label-disabled {
    background-color: rgba(245, 245, 245);
}

.hsw-label-hover {
    color: #4096ff;
}

.hsw-label:before,
.hsw-label:after {
    content: '';
    display: block;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
}

.hsw-label:before {
    top: 0;
    left: 0;
    right: 50%;
    bottom: 30%;
    border-top-left-radius: 4px;
    border-top: 1px solid;
    border-left: 1px solid;
}

.hsw-label:after {
    top: 0;
    left: 50%;
    right: 0;
    bottom: 30%;
    border-top-right-radius: 4px;
    border-top: 1px solid;
    border-right: 1px solid;
}

.hsw-label::after {
    border-top-color: #d9d9d9;
    border-right-color: #d9d9d9;
}

.hsw-label::before {
    border-top-color: #d9d9d9;
    border-left-color: #d9d9d9;
}

.hsw-label-hover::after {
    border-top-color: #4096ff;
    border-right-color: #4096ff;
}

.hsw-label-hover::before {
    border-top-color: #4096ff;
    border-left-color: #4096ff;
}