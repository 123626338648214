@import url('/src/styles/form.css');
@import url('/src/styles/spacing.css');
@import url('/src/styles/coloring.css');
@import url('/src/styles/layout.css');

.flex-center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mb-0 {
  margin-bottom: 0;
}

.m0 {
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.app-bg {
  background-color: white;
}

#intro_message p {
  font-size: 16px;
  line-height: 20px;
  /* text-align: justify; */
}

#intro_message h3 {
  font-size: 20px;
}

@media only screen and (min-width: 601px) {
  #introduction {
    width: 80%;
    margin: 0 auto;
  }
}

@media (prefers-color-scheme: dark) {

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  .app-bg {
    background-color: #16161d;
  }

  .ant-modal-content {
    background-color: #16161d;
  }

  .ant-radio-wrapper {
    color: white;
  }

  .ant-radio-wrapper-disabled {
    color: gray;
  }
}

h4 {
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0em;
  color: rgb(107, 116, 131);
}

p {
  font-size: 12px;
}

.select-offset .ant-select-selection-item {
  margin-left: 16px;
}

.card {
  background-color: white;
  border-radius: 16px;
}

.insight-card {
  padding: 8px;
  margin: 8px;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.backgroundImage {
  /* width: 100%; */
  height: 130px;
  /* background-image: url('https://picsum.photos/seed/picsum/200/300'); */
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
}

.bg-filter {
  backdrop-filter: blur();
  width: fit-content;
}

#terms-and-conditions h3 {
  margin-top: 4px;
  margin-bottom: 4px;
}

#terms-and-conditions p {
  margin-top: 4px;
  margin-bottom: 4px;
}

.background-tint {
  background-color: rgba(0, 0, 0, .2);
  background-blend-mode: multiply;
}