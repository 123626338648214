.mr-1 {
    margin-right: 4px;
}
.mr-2 {
    margin-right: 8px;
}
.mr-3 {
    margin-right: 12px;
}
.mr-4 {
    margin-right: 16px;
}
.mr-5 {
    margin-right: 20px;
}
.mr-6 {
    margin-right: 24px;
}
.mr-7 {
    margin-right: 28px;
}
.mr-8 {
    margin-right: 32px;
}

.mb-1 {
    margin-bottom: 4px;
}
.mb-2 {
    margin-bottom: 8px;
}
.mb-3 {
    margin-bottom: 12px;
}
.mb-4 {
    margin-bottom: 16px;
}
.mb-5 {
    margin-bottom: 20px;
}
.mb-6 {
    margin-bottom: 24px;
}
.mb-7 {
    margin-bottom: 28px;
}
.mb-8 {
    margin-bottom: 32px;
}

.mt-1 {
    margin-top: 4px;
}
.mt-2 {
    margin-top: 8px;
}
.mt-3 {
    margin-top: 12px;
}
.mt-4 {
    margin-top: 16px;
}
.mt-5 {
    margin-top: 20px;
}
.mt-6 {
    margin-top: 24px;
}
.mt-7 {
    margin-top: 28px;
}
.mt-8 {
    margin-top: 32px;
}

.ml-1 {
    margin-left: 4px;
}
.ml-2 {
    margin-left: 8px;
}
.ml-3 {
    margin-left: 12px;
}
.ml-4 {
    margin-left: 16px;
}
.ml-5 {
    margin-left: 20px;
}
.ml-6 {
    margin-left: 24px;
}
.ml-7 {
    margin-left: 28px;
}
.ml-8 {
    margin-left: 32px;
}

.mx-1 {
    margin-right: 4px;
    margin-left: 4px;
}
.mx-2 {
    margin-right: 8px;
    margin-left: 8px;
}
.mx-3 {
    margin-right: 12px;
    margin-left: 12px;
}
.mx-4 {
    margin-right: 16px;
    margin-left: 16px;
}
.mx-5 {
    margin-right: 20px;
    margin-left: 20px;
}
.mx-6 {
    margin-right: 24px;
    margin-left: 24px;
}
.mx-7 {
    margin-right: 28px;
    margin-left: 28px;
}
.mx-8 {
    margin-right: 32px;
    margin-left: 32px;
}

.my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
}
.my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
}
.my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
}
.my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
}
.my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
}
.my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.ma-1 {
    margin: 4px;
}
.ma-2 {
    margin: 8px;
}
.ma-3 {
    margin: 12px;
}
.ma-4 {
    margin: 16px;
}
.ma-5 {
    margin: 20px;
}
.ma-6 {
    margin: 24px;
}
.ma-7 {
    margin: 28px;
}
.ma-8 {
    margin: 32px;
}

.pr-1 {
    padding-right: 4px;
}
.pr-2 {
    padding-right: 8px;
}
.pr-3 {
    padding-right: 12px;
}
.pr-4 {
    padding-right: 16px;
}
.pr-5 {
    padding-right: 20px;
}
.pr-6 {
    padding-right: 24px;
}
.pr-7 {
    padding-right: 28px;
}
.pr-8 {
    padding-right: 32px;
}

.pb-1 {
    padding-bottom: 4px;
}
.pb-2 {
    padding-bottom: 8px;
}
.pb-3 {
    padding-bottom: 12px;
}
.pb-4 {
    padding-bottom: 16px;
}
.pb-5 {
    padding-bottom: 20px;
}
.pb-6 {
    padding-bottom: 24px;
}
.pb-7 {
    padding-bottom: 28px;
}
.pb-8 {
    padding-bottom: 32px;
}

.pt-1 {
    padding-top: 4px;
}
.pt-2 {
    padding-top: 8px;
}
.pt-3 {
    padding-top: 12px;
}
.pt-4 {
    padding-top: 16px;
}
.pt-5 {
    padding-top: 20px;
}
.pt-6 {
    padding-top: 24px;
}
.pt-7 {
    padding-top: 28px;
}
.pt-8 {
    padding-top: 32px;
}

.pl-1 {
    padding-left: 4px;
}
.pl-2 {
    padding-left: 8px;
}
.pl-3 {
    padding-left: 12px;
}
.pl-4 {
    padding-left: 16px;
}
.pl-5 {
    padding-left: 20px;
}
.pl-6 {
    padding-left: 24px;
}
.pl-7 {
    padding-left: 28px;
}
.pl-8 {
    padding-left: 32px;
}

.px-1 {
    padding-right: 4px;
    padding-left: 4px;
}
.px-2 {
    padding-right: 8px;
    padding-left: 8px;
}
.px-3 {
    padding-right: 12px;
    padding-left: 12px;
}
.px-4 {
    padding-right: 16px;
    padding-left: 16px;
}
.px-5 {
    padding-right: 20px;
    padding-left: 20px;
}
.px-6 {
    padding-right: 24px;
    padding-left: 24px;
}
.px-7 {
    padding-right: 28px;
    padding-left: 28px;
}
.px-8 {
    padding-right: 32px;
    padding-left: 32px;
}

.py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
}
.py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
}
.py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
}
.py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
}
.py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
}
.py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.pa-1 {
    padding: 4px;
}
.pa-2 {
    padding: 8px;
}
.pa-3 {
    padding: 12px;
}
.pa-4 {
    padding: 16px;
}
.pa-5 {
    padding: 20px;
}
.pa-6 {
    padding: 24px;
}
.pa-7 {
    padding: 28px;
}
.pa-8 {
    padding: 32px;
}

.mx-n-4 {
    margin-left: -16px;
    margin-right: -16px;
}

.br-sm {
    border-radius: 4px;
}

.br-md {
    border-radius: 16px;
}

.br-md-l {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.br-md-r {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.br-full {
    border-radius: 100%;
}

.no-pad-modal .ant-modal-content {
    padding: 0;
}

.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
